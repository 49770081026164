<template>
  <div class="this-wraper">

    <div class="row">
      <div
        class="col-lg-4"
        v-if="person"
      >
        <p class="robo-20-500">Thông tin bệnh nhân</p>
        <div class="row">
          <div class="col-12">
            <div class="d-flex mb-2">
              <div class="info-container">
                <p class="mb-0 robo-20-500 txt-black">{{person.name}}</p>
                <p class="mb-0 robo-14-400 txt-black mt-1">{{mdtUtils.getGender(person.gender)}}, {{person.address}}</p>
                <p
                  class="mb-0 robo-14-400 txt-black mt-1"
                  v-if="person.birthday"
                >{{formatDmy(person.birthday)}} ({{mdtUtils.getAges(person.birthday)}})</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-lg-8"
        v-if="person"
      >
        <p class="robo-20-500">Chỉ số sinh hiệu</p>
        <div
          class="row my-2"
          :set="filtered_stats = filterStats()"
        >
          <div
            class="col-xxl-4 col-sm-6 d-flex align-items-center"
            v-for="stat in filtered_stats"
            :key="stat.id"
          >
            <p class="robo-16-500 txt-grey-600 mr-1">{{stat.name ? stat.name : '(Mã chỉ số: ' + stat.code + ')'}}: </p>
            <p class="robo-16-500 txt-pri mr-1">{{stat.value || '---'}} </p>
            <p class="robo-16-400 txt-pri">{{stat.unit}}</p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="pr-3"
      v-if="disease"
    >
      <p class="robo-20-500 border-bottom txt-black mb-4">Tóm tắt quá trình diễn biến bệnh, quá trình điều trị và chăm sóc</p>
      <!-- thông tin  -->
      <div class="d-flex">
        <div class="w-67 disiease-wraper">
          <div class="col-12 mb-3">
            <label
              for=""
              class="robo-20-500 txt-pri"
            >
              <svg
                class="mr-2"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 5.32998C13.01 3.97998 14.63 3.09998 16.44 3.09998C19.51 3.09998 22 5.59998 22 8.68998C22 9.59377 21.8904 10.4457 21.6931 11.25H17.4684C17.3942 11.25 17.3238 11.217 17.2763 11.16L16.055 9.69448C15.2471 8.72498 13.7098 8.9034 13.1454 10.0322L11.6266 13.0698C11.5393 13.2444 11.2945 13.256 11.191 13.0905L9.92975 11.0725C9.60996 10.5608 9.04913 10.25 8.44575 10.25H2.11193C2.03885 9.74778 2 9.22808 2 8.68998C2 5.59998 4.49 3.09998 7.56 3.09998C9.37 3.09998 10.99 3.97998 12 5.32998ZM2.44243 11.75C2.46735 11.8339 2.49321 11.9172 2.52 12C4.1 17 8.97 19.99 11.38 20.81C11.72 20.93 12.28 20.93 12.62 20.81C14.907 20.0318 19.4092 17.2996 21.2131 12.75H17.4684C16.949 12.75 16.4565 12.5193 16.124 12.1203L14.9027 10.6548C14.7873 10.5163 14.5677 10.5417 14.487 10.703L12.9682 13.7406C12.3571 14.9629 10.6433 15.0444 9.91898 13.8855L8.65775 11.8675C8.61207 11.7944 8.53195 11.75 8.44575 11.75H2.44243Z"
                  fill="#20419B"
                />
              </svg>
              Chẩn đoán
            </label>
          </div>
          <div class="col-12 mb-3">
            <label
              for=""
              class="robo-16-500 txt-muted mb-2"
            >Tên bệnh</label>
            <p class="robo-16-400 pre-wrap">
              {{disease.diseases_name}}
            </p>
          </div>
          <div class="col-12 mb-3">
            <label
              for=""
              class="robo-16-500 txt-muted mb-2"
            >Diễn biến bệnh</label>
            <p class="robo-16-400 pre-wrap">
              {{disease.diseases_stage}}
            </p>
          </div>
          <div class="col-12 mb-3">
            <label
              for=""
              class="robo-20-500 txt-pri my-3 d-flex align-items-center"
            >
              <svg
                class="mr-2"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18 6.5C18 9.53757 15.5376 12 12.5 12C9.46243 12 7 9.53757 7 6.5C7 6.33146 7.00758 6.16468 7.02242 6H4C2.89543 6 2 6.89543 2 8V21C2 22.1046 2.89543 23 4 23H20C21.1046 23 22 22.1046 22 21V8C22 6.89543 21.1046 6 20 6H17.9776C17.9924 6.16468 18 6.33146 18 6.5ZM4.75 17.5C4.33579 17.5 4 17.8358 4 18.25C4 18.6642 4.33579 19 4.75 19H19.25C19.6642 19 20 18.6642 20 18.25C20 17.8358 19.6642 17.5 19.25 17.5H4.75ZM6 15.25C6 14.8358 6.33579 14.5 6.75 14.5H17.25C17.6642 14.5 18 14.8358 18 15.25C18 15.6642 17.6642 16 17.25 16H6.75C6.33579 16 6 15.6642 6 15.25Z"
                  fill="#20419B"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17 6.5C17 8.98528 14.9853 11 12.5 11C10.0147 11 8 8.98528 8 6.5C8 4.01472 10.0147 2 12.5 2C14.9853 2 17 4.01472 17 6.5ZM11.6208 4.75C11.6208 4.33579 11.9566 4 12.3708 4C12.785 4 13.1208 4.33579 13.1208 4.75V6.38818L14.6389 6.1404C15.0477 6.07367 15.4332 6.35099 15.5 6.75979C15.5667 7.1686 15.2894 7.55409 14.8806 7.62081L12.8717 7.94867C12.7928 7.96155 12.7147 7.96161 12.6396 7.95037C12.5562 7.98243 12.4655 8 12.3708 8C11.9566 8 11.6208 7.66421 11.6208 7.25V4.75Z"
                  fill="#20419B"
                />
              </svg>
              Tình trạng hiện tại
            </label>
            <p class="robo-16-400 pre-wrap">
              {{disease.diseases_current_cure}}
            </p>
          </div>
          <div class="col-12 mb-3">
            <label
              for=""
              class="robo-20-500 txt-pri my-3 d-flex align-items-center"
            >
              <svg
                class="mr-2"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.2125 22.5H4.3875H7.10625H10.9125C11.5133 22.5 12 22.8356 12 23.25C12 23.6644 11.5133 24 10.9125 24H2.2125C1.61166 24 1.125 23.6644 1.125 23.25C1.125 22.8356 1.61166 22.5 2.2125 22.5Z"
                  fill="#20419B"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.6228 8.65593C19.9423 8.2054 19.836 7.58122 19.3855 7.26177L14.491 3.7913C14.0405 3.47185 13.4163 3.57811 13.0969 4.02864L7.31277 12.1861L19.0444 9.47167L19.6228 8.65593ZM6.44515 13.4097L6.15594 13.8176C5.8365 14.2681 5.94275 14.8923 6.39328 15.2117L11.2877 18.6822C11.7383 19.0017 12.3625 18.8954 12.6819 18.4449L18.1768 10.6953L6.44515 13.4097Z"
                  fill="#20419B"
                />
                <rect
                  x="17.0422"
                  y="1.92236"
                  width="4"
                  height="5"
                  rx="1"
                  transform="rotate(35.3389 17.0422 1.92236)"
                  fill="#20419B"
                />
                <path
                  d="M10.4571 13.8026C10.6169 13.5774 10.9289 13.5243 11.1542 13.684C11.3795 13.8437 11.4326 14.1558 11.2729 14.3811L5.05028 23.1569C4.89056 23.3822 4.57846 23.4353 4.3532 23.2756C4.12794 23.1159 4.07481 22.8038 4.23453 22.5785L10.4571 13.8026Z"
                  fill="#20419B"
                />
                <rect
                  x="22.5151"
                  y="4.57715"
                  width="2"
                  height="8"
                  rx="1"
                  transform="rotate(125.339 22.5151 4.57715)"
                  fill="#20419B"
                />
              </svg>
              Điều trị</label>
            <p class="robo-16-400 pre-wrap">
              {{disease.diseases_previous_cure}}
            </p>
          </div>
          <div class="col-12 mb-3">
            <label
              for=""
              class="robo-20-500 txt-pri my-3 d-flex align-items-center"
            >
              <svg
                class="mr-2"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_14272_101952)">
                  <path
                    d="M6.75366 7.49268C7.16788 7.49268 7.50366 7.15689 7.50366 6.74268C7.50366 6.32846 7.16788 5.99268 6.75366 5.99268C6.33945 5.99268 6.00366 6.32846 6.00366 6.74268C6.00366 7.15689 6.33945 7.49268 6.75366 7.49268Z"
                    fill="#20419B"
                  />
                  <path
                    d="M2.625 14.7188H14.25V16.5938C14.25 17.2151 13.7464 17.7188 13.125 17.7188H3.75C3.12863 17.7188 2.625 17.2151 2.625 16.5938V14.7188Z"
                    fill="#E3FCFD"
                  />
                  <path
                    d="M21.1168 1.29272L22.7079 2.88385C23.1474 3.32335 23.1474 4.03547 22.7079 4.47497L17.5741 9.60872C17.1346 10.0482 16.4225 10.0482 15.983 9.60872L14.3919 8.0176C13.9524 7.5781 13.9524 6.86597 14.3919 6.42647L19.5256 1.29272C19.9651 0.853598 20.6776 0.853598 21.1168 1.29272Z"
                    fill="#20419B"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22.9837 2.07764L23.7787 1.27889C24.0693 0.986387 24.0693 0.513887 23.7787 0.221387C23.4862 -0.0692383 23.0137 -0.0692383 22.7212 0.221387L21.9224 1.01639L21.6599 0.750137C20.9279 0.0181367 19.7407 0.0181367 19.0087 0.750137L13.8749 5.88389C13.1429 6.61589 13.1429 7.80314 13.8749 8.53514L15.4649 10.1251C16.1969 10.8571 17.3842 10.8571 18.1162 10.1251L20.1299 8.10764L21.2324 7.00889L23.2499 4.99139C23.9819 4.25939 23.9819 3.07214 23.2499 2.34014L22.9837 2.07764ZM17.0549 9.06764L22.1887 3.93014C22.3342 3.78389 22.3342 3.54764 22.1887 3.40139L20.5987 1.81139C20.4524 1.66589 20.2162 1.66589 20.0699 1.81139L14.9324 6.94514C14.7869 7.09139 14.7869 7.32764 14.9324 7.47389L16.5224 9.06764L16.5247 9.06989C16.6717 9.21576 16.9091 9.21464 17.0549 9.06764Z"
                    fill="#20419B"
                  />
                  <path
                    d="M4.875 23.25C4.875 23.6644 5.21062 24 5.625 24H18C18.4144 24 18.75 23.6644 18.75 23.25C18.75 22.8356 18.4144 22.5 18 22.5H5.625C5.21062 22.5 4.875 22.8356 4.875 23.25Z"
                    fill="#20419B"
                  />
                  <path
                    d="M3 7.5H3.82875C3.924 7.97025 4.11 8.41725 4.37625 8.81625L3.975 9.21375C3.68213 9.50663 3.68213 9.98212 3.975 10.275C4.26788 10.5679 4.74338 10.5679 5.03625 10.275L5.44125 9.87C5.83763 10.1359 6.28237 10.3219 6.75 10.4175V11.625C6.75 12.0394 7.08563 12.375 7.5 12.375C7.91437 12.375 8.25 12.0394 8.25 11.625V10.425C8.72025 10.3297 9.16725 10.1438 9.56625 9.8775L9.975 10.275C10.2679 10.5679 10.7434 10.5679 11.0363 10.275C11.3291 9.98212 11.3291 9.50663 11.0363 9.21375L10.6313 8.80875C10.8968 8.412 11.0828 7.96763 11.1788 7.5H11.25C11.6644 7.5 12 7.16437 12 6.75C12 6.33563 11.6644 6 11.25 6H11.175C11.0797 5.53088 10.8938 5.085 10.6275 4.6875L11.4788 3.83625C11.7716 3.54337 11.7716 3.06788 11.4788 2.775C11.1859 2.48213 10.7104 2.48213 10.4175 2.775L9.57 3.61875C9.16988 3.35175 8.72137 3.16575 8.25 3.07125V1.875C8.25 1.46062 7.91437 1.125 7.5 1.125C7.08563 1.125 6.75 1.46062 6.75 1.875V3.07125C6.28088 3.1665 5.835 3.3525 5.4375 3.61875L5.4075 3.58875C5.1 3.31125 4.62562 3.33525 4.34812 3.64275C4.08975 3.92887 4.0905 4.36462 4.35 4.65L4.38 4.68C4.11263 5.07975 3.92663 5.52825 3.8325 6H3C2.58562 6 2.25 6.33563 2.25 6.75C2.25 7.16437 2.58562 7.5 3 7.5ZM7.5 5.625C7.91437 5.625 8.25 5.28937 8.25 4.875V4.63125C9.14813 4.94888 9.74888 5.7975 9.75 6.75C9.75075 7.08638 9.675 7.41825 9.52875 7.72125L9.375 7.54125C9.06038 7.272 8.58675 7.30837 8.3175 7.623C8.07713 7.90387 8.07713 8.31787 8.3175 8.59875L8.49 8.77125C8.18212 8.92275 7.84312 9.00112 7.5 9C6.25725 8.99587 5.25337 7.98563 5.25712 6.74288C5.26012 5.79488 5.85713 4.95038 6.75 4.63125V4.875C6.75 5.28937 7.08563 5.625 7.5 5.625Z"
                    fill="#20419B"
                  />
                  <path
                    d="M7 20.9096H6.25C6.25 21.1946 6.41154 21.455 6.6669 21.5816L7 20.9096ZM21.2645 6.61577C21.0523 6.26004 20.5919 6.14369 20.2361 6.3559C19.8804 6.56811 19.7641 7.02851 19.9763 7.38423L21.2645 6.61577ZM7.75 17.5C7.75 17.0858 7.41421 16.75 7 16.75C6.58579 16.75 6.25 17.0858 6.25 17.5H7.75ZM21.25 12C21.25 17.087 16.9906 21.25 11.6875 21.25V22.75C17.7752 22.75 22.75 17.9586 22.75 12H21.25ZM11.6875 21.25C10.117 21.25 8.63766 20.8843 7.3331 20.2376L6.6669 21.5816C8.1751 22.3292 9.88211 22.75 11.6875 22.75V21.25ZM19.9763 7.38423C20.7869 8.74303 21.25 10.3186 21.25 12H22.75C22.75 10.0386 22.2088 8.1987 21.2645 6.61577L19.9763 7.38423ZM7.75 20.9096V17.5H6.25V20.9096H7.75Z"
                    fill="#20419B"
                  />
                  <path
                    d="M2 16C2 14.8954 2.89543 14 4 14H13C14.1046 14 15 14.8954 15 16C15 17.1046 14.1046 18 13 18H4C2.89543 18 2 17.1046 2 16Z"
                    fill="#20419B"
                  />
                  <path
                    d="M0 14.75C0 14.3358 0.335786 14 0.75 14H16.25C16.6642 14 17 14.3358 17 14.75C17 15.1642 16.6642 15.5 16.25 15.5H0.75C0.335786 15.5 0 15.1642 0 14.75Z"
                    fill="#20419B"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_14272_101952">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
              Bệnh khác
            </label>
            <p class="robo-16-400 pre-wrap">
              {{disease.background_diseases}}
            </p>
          </div>
        </div>
        <div
          class="w-33 p-3 bg--gray"
          v-if="symptoms_values && symptoms_values.data"
        >
          <p class="robo-20-500 txt-black">Dấu hiệu bất thường</p>
          <div class="symptoms-wraper">
            <div
              class="row mb-3"
              v-for="symptoms_value in symptoms_values.data"
              :key="symptoms_value.id"
            >
              <div class="symptom-item">
                <div class="symptom-item-l">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="ml--3"
                  >
                    <rect
                      width="12"
                      height="12"
                      rx="6"
                      fill="#6D6E6F"
                    />
                  </svg>
                  <div class="h-100 border-left"></div>

                </div>
                <div class="symptom-item-r">
                  <p class="robo-16-500 mb-2">{{symptoms_value && symptoms_value.person_symptom && symptoms_value.person_symptom.symptom_name}}</p>
                  <p
                    class="robo-14-400 mb-2"
                    v-if="symptoms_value.description"
                  >{{symptoms_value.description}}</p>
                  <div class="robo-12-400 mb-0">{{formatDateHMDMY(symptoms_value.updated_at)}}</div>
                </div>
              </div>
            </div>

          </div>
          <div class="text-center">
            <a
              href="javascript:;"
              class="txt-pri robo-16-400"
              v-if="symptom_current_page < symptoms_values.last_page"
              @click="nextSymptom()"
            >Xem thêm</a>
          </div>
        </div>

      </div>
      <!-- xet nghiệm -->
      <div
        class="mt-4"
        v-if="test_results && test_results.data"
      >
        <p class="robo-20-500 txt-black">Thông tin xét nghiệm</p>

        <div>
          <div class="table-responsive">
            <table class="table table-vcenter table-mobile-md card-table table-scroll">
              <thead class="d-none d-md-table">
                <tr class="">
                  <th
                    class="robo-20-500"
                    style="background-color: #FFF !important;"
                  ><span class="ml-sm-1">Tên xét nghiệm</span></th>
                  <th
                    class="robo-20-500"
                    style="background-color: #FFF !important;"
                  ><span class="">Ngày tải lên</span></th>
                  <th
                    class="robo-20-500"
                    style="background-color: #FFF !important;"
                  ><span class="">Người cập nhật</span></th>
                  <th
                    class="robo-20-500 text-md-center"
                    style="background-color: #FFF !important;"
                  ><span class="ml-sm-1">Hành động</span></th>
                </tr>
              </thead>
              <tbody
                class="h-100"
                v-if="test_results && test_results.count"
              >
                <tr
                  v-for="test in test_results.data"
                  :key="test.id"
                  class="border-bottom mb-3 mt-3 cursor-pointer"
                  :set="status = getTRStatus(test)"
                >
                  <td
                    data-label="Tên xét nghiệm"
                    class="table-td-name-avatar robo-20-400"
                  >
                    <span class="ml-md-2">{{test.test_result_name}}</span>
                  </td>
                  <td
                    data-label="Ngày tải lên"
                    class="table-td-action"
                  >
                    <span class="">{{formatDmy(test.created_date)}}</span>
                  </td>
                  <td
                    data-label="Người cập nhật"
                    class="table-td-action"
                  >
                    <span
                      v-if="test.created_by"
                      class=""
                    >
                      <span v-if="test.created_role == 3">{{test.created_by ? test.created_by.name : ''}}</span>
                      <span v-if="test.created_role == 2">{{test.created_by && test.created_by.doctor? test.created_by.doctor.name : ''}}</span>
                      <span v-if="test.created_role == 1">HODO</span>
                    </span>
                  </td>
                  <td
                    data-label="Hành động"
                    class="table-td-action text-md-center"
                  >
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class=""
                      @click="viewDetailTest(test)"
                    >
                      <path
                        d="M20.7732 15.9999C20.7732 18.6399 18.6399 20.7732 15.9999 20.7732C13.3599 20.7732 11.2266 18.6399 11.2266 15.9999C11.2266 13.3599 13.3599 11.2266 15.9999 11.2266C18.6399 11.2266 20.7732 13.3599 20.7732 15.9999Z"
                        stroke="#20419B"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15.9998 27.0271C20.7065 27.0271 25.0931 24.2538 28.1465 19.4538C29.3465 17.5738 29.3465 14.4138 28.1465 12.5338C25.0931 7.73378 20.7065 4.96045 15.9998 4.96045C11.2931 4.96045 6.90646 7.73378 3.85312 12.5338C2.65312 14.4138 2.65312 17.5738 3.85312 19.4538C6.90646 24.2538 11.2931 27.0271 15.9998 27.0271Z"
                        stroke="#20419B"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                  </td>
                </tr>
              </tbody>
              <tbody v-if="tr_current_page < test_results.last_page">
                <tr>
                  <td
                    colspan="4"
                    class="text-center"
                  >

                    <a
                      href="javascript:;"
                      class="txt-pri robo-16-400"
                      @click="nextTestResult()"
                    >Xem thêm</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- thuốc -->
      <div
        class="mt-4"
        v-if="medicines && medicines.total"
      >
        <p class="robo-20-500 txt-black">Thuốc</p>
        <div class="table-responsive">
          <table class="table table-vcenter table-mobile-md card-table">
            <thead>
              <tr>
                <th class="robo-20-500"><span class="ml-sm-1">Tên thuốc</span></th>
                <th class="robo-20-500">ĐVT</th>
                <th class="robo-20-500">SL</th>
                <th class="robo-20-500">Lần/ ngày</th>
                <th class="robo-20-500">Liều lượng/lần</th>
                <th class="robo-20-500">Cách ngày</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="med in medicines.data"
                :key="'med'+med.id"
              >
                <td data-label="Tên thuốc">
                  <span class="ml-sm-1">{{med.name}}</span>
                </td>
                <td data-label="ĐVT">
                  {{med.amount_unit}}
                </td>
                <td data-label="SL">
                  {{med.amount}}
                </td>
                <td data-label="Lần/ ngày">
                  {{med.freq_per_day}}
                </td>
                <td data-label="Liều lượng/lần">
                  {{med.amount_per_one}}
                </td>
                <td data-label="Cách ngày">
                  {{med.gap_day}}
                </td>
              </tr>
            </tbody>

            <tbody v-if="med_current_page < medicines.last_page">
              <tr>
                <td
                  colspan="6"
                  class="text-center"
                >

                  <a
                    href="javascript:;"
                    class="txt-pri robo-16-400"
                    @click="nextMeds()"
                  >Xem thêm</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- tài liệu gốc -->

      <div
        class="my-4"
        v-if="documents && documents.total"
      >
        <p class="robo-20-500 txt-black">Tài liệu gốc</p>
        <div class="table-responsive">
          <table class="table table-vcenter table-mobile-md card-table">
            <thead>
              <tr>
                <th class="robo-20-500"><span class="ml-sm-1">Tài liệu</span></th>
                <th class="robo-20-500">Loại tài liệu</th>
                <th class="robo-20-500">Ngày tải lên</th>
                <th class="robo-20-500 text-md-right">Hành động</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="doc in documents.data"
                :key="'document'+doc.id"
              >
                <td data-label="Tài liệu">
                  <span class="ml-sm-1">{{doc.content}}</span>
                </td>
                <td data-label="Loại tài liệu">
                  {{getDocType(doc.type)}}
                </td>
                <td data-label="Ngày tải lên">
                  {{formatDmy(doc.created_at)}}
                </td>
                <td
                  data-label="Hành động"
                  class="text-md-right"
                >
                  <a
                    target="_blank"
                    :href="getLinkDownload(doc.link)"
                    :download="doc.name"
                  >
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.0003 29.3332H20.0003C26.667 29.3332 29.3337 26.6665 29.3337 19.9998V11.9998C29.3337 5.33317 26.667 2.6665 20.0003 2.6665H12.0003C5.33366 2.6665 2.66699 5.33317 2.66699 11.9998V19.9998C2.66699 26.6665 5.33366 29.3332 12.0003 29.3332Z"
                        stroke="#20419B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 15.3467L16 19.3467L20 15.3467"
                        stroke="#20419B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16 19.3468V8.68018"
                        stroke="#20419B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 22.0137C13.1867 23.747 18.8133 23.747 24 22.0137"
                        stroke="#20419B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                  </a>
                </td>
              </tr>
            </tbody>

            <tbody v-if="doc_current_page < documents.last_page">
              <tr>
                <td
                  colspan="6"
                  class="text-center"
                >

                  <a
                    href="javascript:;"
                    class="txt-pri robo-16-400"
                    @click="nextMeds()"
                  >Xem thêm</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- modal test  -->
    <div
      class="modal fade"
      id="modalTest"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalTestLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-xl"
        role="document"
      >
        <div class="modal-content">
          <div class="d-flex justify-content-between align-items-center p-3">
            <h5
              class="robo-24-500"
              id="modalTestLabel"
            >{{(showing_test && showing_test.test_result_name) || 'Xét nghiệm'}}</h5>
            <a
              href="javascript:;"
              class="close mr-2 txt-black robo-24-400"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </a>
          </div>
          <div class="modal-body mb-3">
            <TestsTable
              v-if="showing_test"
              :documents="[]"
              :list_tests="list_tests"
              @getTestsByDiseases="getTests(showing_test.id)"
            />

          </div>
        </div>
      </div>
    </div>
    <!-- modal result  -->

    <div
      class="modal fade"
      id="modalResult"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalResultLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-xl"
        role="document"
      >
        <div class="modal-content">
          <div class="d-flex justify-content-between align-items-center p-3">
            <h5
              class="robo-24-500"
              id="modalResultLabel"
            >{{(showing_test && showing_test.test_result_name) || 'Xét nghiệm'}}</h5>
            <a
              href="javascript:;"
              class="close mr-2 txt-black robo-24-400"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </a>
          </div>
          <div class="modal-body mb-3">
            <ResultTable
              v-if="showing_test"
              :documents="[]"
              :list_results="list_results"
              @getResultsByDiseases="getResults(showing_test.id)"
            />

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mdtUtils from '../../utils/mdtUtils'
import TestsTable from '../../components/TestsGroup/TestsTable.vue'
import ResultTable from '../../components/TestsGroup/ResultTable.vue'
import appUtils from '../../utils/appUtils'

const tr_statuses = [
  { id: 1, name: 'Đang xử lý', class: 'text-warning' },
  { id: 2, name: 'Hoàn thành', class: 'text-success' },
  { id: 3, name: 'Không xử lý được tài liệu', class: 'text-danger' }
]
export default {
  name: 'DiseaseDetailForMdt',
  components: { TestsTable, ResultTable },
  props: [],
  data () {
    return {
      mdtUtils,
      tr_statuses,
      person: null,
      disease: null,
      showing_test: null,
      monitor_stats: [],
      symptoms_values: [],
      medicines: [],
      test_results: [],
      list_tests: [],
      list_results: [],
      documents: [],
      symptom_current_page: 0,
      tr_current_page: 0,
      med_current_page: 0,
      doc_current_page: 0
    }
  },
  mounted () {
    let self = this
    this.showMdtDiseaseDetail()
    this.$nextTick(function () {
      window.$('#modalTest').on('hiden.bs.modal', function (e) {
        self.showing_test = null
        self.list_tests = []
      })
    })
  },
  methods: {
    filterStats () {
      let self = this
      let stats = []
      self.monitor_stats.forEach(e => {
        let stat_value = self.person?.stats?.find(s => s.code === e.code) || null
        stats.push({
          name: e.name,
          value: stat_value ? stat_value.value : '--',
          unit: e.unit
        })
      })
      return stats
    },
    async showMdtDiseaseDetail () {
      let self = this
      if (!self.$route.params.room_id || !self.$route.params.pd_id) return
      await self.$rf.getRequest('DoctorRequest').showMdtDiseaseDetail(self.$route.params.room_id, self.$route.params.pd_id).then((r) => {
        self.person = r?.data?.person
        self.disease = r?.data
        self.getStats()
        self.getPersonSymptomsValue()
        self.getPersonDiseaseTestResult()
        self.getPersonDiseaseMedicines()
        self.getPersonDiseaseDocs()
      }).catch((e) => {
        console.log(e)
        self.$router.push('/')
      })
    },
    async getStats () {
      let self = this
      let params = {}
      self.monitor_stats = []
      await self.$rf.getRequest('DoctorRequest').getMdtDiseaseMonitorStats(self.$route.params.room_id, self.$route.params.pd_id, params).then(resp => {
        if (resp && resp.data && resp.data.count) {
          self.monitor_stats = resp.data.data
          // stats.forEach(function (stat, index) {
          //   if (stat.is_monitor === 1) {
          //     self.monitor_stats.push(stat)
          //   }
          // })
        }
      })
    },
    async getPersonSymptomsValue (is_next = false) {
      let self = this
      if (!is_next) {
        self.symptoms_values = []
      }
      let params = { has_symptom: 1, limit: 20, page: self.symptom_current_page + 1 }
      try {
        await self.$rf.getRequest('DoctorRequest').getMdtDiseaseSymptoms(self.$route.params.room_id, self.$route.params.pd_id, params).then(res => {
          if (res && res.data) {
            self.symptom_current_page = res.data.current_page
            if (!is_next) {
              self.symptoms_values = res.data
            } else {
              if (res.data?.count) {
                let old = self.symptoms_values.data
                let newObj = res.data.data
                self.symptoms_values.data = old.concat(newObj)
              }
            }
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async getPersonDiseaseTestResult (is_next = false) {
      let self = this
      if (!is_next) {
        self.test_results = []
      }
      let params = {
        limit: 10,
        page: self.tr_current_page + 1,
        sort: 'created_at',
        sort_by: 'desc',
        finished: 0
      }
      await self.$rf.getRequest('DoctorRequest').getMdtDiseaseTestResults(self.$route.params.room_id, self.$route.params.pd_id, params).then((res) => {
        self.tr_current_page = res.data.current_page
        if (!is_next) {
          self.test_results = res.data
        } else {
          if (res.data?.count) {
            let old = self.test_results.data
            let newObj = res.data.data
            self.test_results.data = old.concat(newObj)
          }
        }
      })
    },
    async getPersonDiseaseMedicines (is_next = false) {
      let self = this
      if (!is_next) {
        self.medicines = []
      }
      let params = {
        limit: 10,
        page: self.med_current_page + 1,
        sort: 'created_at',
        sort_by: 'desc',
        finished: 0
      }
      await self.$rf.getRequest('DoctorRequest').getMdtDiseaseMedicines(self.$route.params.room_id, self.$route.params.pd_id, params).then((res) => {
        self.med_current_page = res.data.current_page
        if (!is_next) {
          self.medicines = res.data
        } else {
          if (res.data?.count) {
            let old = self.medicines.data
            let newObj = res.data.data
            self.medicines.data = old.concat(newObj)
          }
        }
      })
    },
    async getPersonDiseaseDocs (is_next = false) {
      let self = this
      if (!is_next) {
        self.documents = []
      }
      let params = {
        limit: 10,
        page: self.doc_current_page + 1,
        sort: 'created_at',
        sort_by: 'desc',
        finished: 0
      }
      await self.$rf.getRequest('DoctorRequest').getMdtDiseaseDocuments(self.$route.params.room_id, self.$route.params.pd_id, params).then((res) => {
        self.doc_current_page = res.data.current_page
        if (!is_next) {
          self.documents = res.data
        } else {
          if (res.data?.count) {
            let old = self.documents.data
            let newObj = res.data.data
            self.documents.data = old.concat(newObj)
          }
        }
      })
    },
    async getTests (test_id) {
      let self = this
      try {
        var params = {
          limit: 1000,
          person_test_result_id: test_id
        }
        await self.$rf.getRequest('DoctorRequest').getMdtDiseaseTests(self.$route.params.room_id, self.$route.params.pd_id, params).then(res => {
          if (res && res.data) {
            self.list_tests = res.data
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async getResults (test_id) {
      let self = this
      try {
        var params = {
          limit: 1000,
          person_test_result_id: test_id
        }
        await self.$rf.getRequest('DoctorRequest').getMdtDiseaseResults(self.$route.params.room_id, self.$route.params.pd_id, params).then(res => {
          if (res && res.data) {
            self.list_results = res.data
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    viewDetailTest (t) {
      let type = t.type
      if (type !== 3) {
        this.showing_test = t

        if (type === 2) {
          this.getResults(t.id)
          this.showResultModal(true)
        }
        if (type === 1) {
          this.getTests(t.id)
          this.showTestModal(true)
        }
      } else {
        if (t.pac && t.pac.status === 2) {
          this.getUrl(t.pac.accession)
        }
      }
    },
    async getUrl (accession) {
      let self = this
      let params = {
        accession: accession
      }
      await self.$rf.getRequest('DoctorRequest').getUrlPac(params).then((r) => {
        if (r.data) {
          window.open(r.data)
        }
      })
    },
    showResultModal (show) {
      return window.$('#modalResult').modal(show ? 'show' : 'hide')
    },
    showTestModal (show) {
      return window.$('#modalTest').modal(show ? 'show' : 'hide')
    },
    nextSymptom () {
      this.getPersonSymptomsValue(true)
    },
    nextTestResult () {
      this.getPersonDiseaseTestResult(true)
    },
    nextMeds () {
      this.getPersonDiseaseMedicines(true)
    },
    formatDmy (date) {
      return window.moment(date, 'YYYY/MM/DD').format('DD/MM/YYYY')
    },
    formatDateHMDMY (date) {
      return window.moment(date).format('HH:mm - DD/MM/YYYY')
    },
    getTRStatus (tr) {
      if (tr?.type !== 3) {
        return this.tr_statuses[1]
      } else {
        if (tr.pac) {
          return this.tr_statuses.find(s => s.id === tr.pac.status)
        } else {
          return this.tr_statuses[0]
        }
      }
    },
    getLinkDownload (link) {
      return appUtils.getDocumentURL(link)
    },
    getDocType (type) {
      return type === 1 ? 'Sổ khám bệnh' : type === 2 ? 'Đơn thuốc' : type === 3 ? 'Giấy xét nghiệm' : ''
    }
  }
}
</script>

<style scoped>
.avatar {
  width: 38px;
  height: 38px;
}

button {
  width: 140px;
}
@media (min-width: 768px) {
  .w-33 {
    width: 50%;
  }
  .w-67 {
    width: 50%;
  }
  .this-wraper {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
  }
}
@media (max-width: 767px) {
  .w-33 {
    width: 100%;
  }
  .w-67 {
    width: 100%;
  }
  .this-wraper {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 32px;
  }
}
@media (min-width: 1280px) {
  .this-wraper {
    padding-left: 73px;
    padding-right: 73px;
    padding-top: 50px;
  }
  .w-33 {
    width: 33%;
  }
  .w-67 {
    width: 67%;
  }
}

.pre-wrap {
  white-space: pre-wrap;
}
.symptom-item {
  display: flex;
  padding: 10px;
  padding-left: 25px;
  width: 100% !important;
}
.symptom-item-l {
  width: 15px;
}
.symptom-item-r {
  width: calc(100% - 15px);
  padding-left: 16px;
}
.symptom-item-r p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.symptom-item-l svg {
  margin-left: -6px;
  margin-bottom: 5px;
}
.symptoms-wraper {
  height: 600px;
  overflow: auto;
  max-width: 100%;
  overflow-x: hidden;
}
</style>