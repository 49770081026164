<template>
  <div class="py-4 px-12 list-mdt">
    <span class="flex text-blue-900 text-2xl font-semibold mb-6">{{
      $t("multidisciplinary_board.lbl_mdt_title")
    }}</span>
    <!-- body -->
    <div class="row mt-4 body-list">
      <div class="flex flex-col md:flex-row items-center mb-4 gap-2">
        <div
          class="flex items-center border bg-white rounded-md border-grey-300 p-2 min-w-full md:min-w-max md:w-80"
        >
          <IconSearch class="cursor-pointer" />
          <input
            v-model="valueSearch"
            @keypress.enter="() => getRooms({ page: 1 })"
            class="border-0 txt-grey-500 ml-2 focus:outline-none w-full"
            :placeholder="
              $t('lbl_enter_the_name_of_the_multidisciplinary_board')
            "
          />
        </div>
        <div
          class="flex items-center rounded-md min-w-full md:min-w-max md:w-60"
        >
          <div class="input-group input-search-box">
            <select
              class="form-select text-blue-900 robo-14-400"
              aria-label="Default select example"
              v-model="mdtFilter"
            >
              <option
                :class="
                  mdtFilter == mdtRoomOptions.all
                    ? 'text-blue-900 font-weight-bold bg-f2'
                    : 'txt-black'
                "
                :value="mdtRoomOptions.all"
              >
                {{ $t("lbl_all_mdt") }}
              </option>
              <option
                class="robo-14-400"
                :class="
                  mdtFilter == mdtRoomOptions.oneTime
                    ? 'text-blue-900 font-weight-bold bg-f2'
                    : 'txt-black'
                "
                :value="mdtRoomOptions.oneTime"
              >
                {{ $t("lbl_mdt__one_time") }}
              </option>
              <option
                class="robo-14-400"
                :class="
                  mdtFilter == mdtRoomOptions.day
                    ? 'text-blue-900 font-weight-bold bg-f2'
                    : 'txt-black'
                "
                :value="mdtRoomOptions.day"
              >
                {{ $t("lbl_mdt_room_daily") }}
              </option>
              <option
                class="robo-14-400"
                :class="
                  mdtFilter == mdtRoomOptions.week
                    ? 'text-blue-900 font-weight-bold bg-f2'
                    : 'txt-black'
                "
                :value="mdtRoomOptions.week"
              >
                {{ $t("lbl_weekly_mdt_room") }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <!-- left -->
      <div class="col-md-6">
        <div
          class="w-100 mb-4"
          v-if="mdt_rooms && mdt_rooms.length && !loading"
        >
          <div
            v-for="item in mdt_rooms"
            :key="item.id"
            @click="onShowMdtRoom(item.id, item.clinic, item.organization_id)"
          >
            <div
              class="room-item cursor-pointer md:flex p-6"
              :class="showing_mdt_room_id == item.id ? 'active-item' : ''"
            >
              <p
                :class="
                  showing_mdt_room_id == item.id ? 'tex-white' : 'txt-grey-600'
                "
                class="robo-16-400 mb-2 max-line-1 min-w-48 mr-2"
              >
                {{ mdtUtils.getTimeStringDetail(item) }}
              </p>
              <div
                :class="
                  showing_mdt_room_id == item.id ? 'tex-white' : 'txt-black'
                "
                class="robo-18-500 mb-1"
              >
                <div class="max-line-1 mt-2">{{ item.name }}</div>
                <div
                  :class="
                    showing_mdt_room_id == item.id
                      ? 'tex-white'
                      : 'text-blue-900'
                  "
                  class="text-sm"
                >
                  {{
                    item.clinic
                      ? `(${item.clinic.name})`
                      : item.organization_id
                      ? `(${getNameOrg(item.organization_id)})`
                      : ""
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex mb-2 flex-col xl:flex-row justify-between items-center"
        >
          <div class="fs-16 sm:mb-2 xl:mb-0">
            {{ $t("fm_pagination", { countItems, totalItems }) }}
          </div>
          <el-pagination
            background
            layout="prev, pager, next"
            :pager-count="5"
            :page-size="DEFAULT_SIZE_OF_PAGE"
            :total="totalItems"
            class="el-pagination-mdt"
            @next-click="handleChangePage"
            @prev-click="handleChangePage"
            @current-change="handleChangePage"
            hide-on-single-page
          ></el-pagination>
        </div>
      </div>
      <!-- right -->
      <div class="text-center col-md-6">
        <img
          src="../../../public/assets/images/MDT/Frame.png"
          class="img-1 mb-3"
          alt
        />
        <p class="text-center robo-24-500 txt-black fw-700">
          {{ $t("multidisciplinary_board.lbl_desc_title") }}
        </p>
        <p class="text-center robo-14-400 txt-black">
          {{ $t("multidisciplinary_board.lbl_desc_content") }}
        </p>
        <div class="dropdown" v-if="isAction !== 'NEWBIE'">
          <button class="btn bg-pri bd-pri text-white mt-3" @click="goToCreate">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.25 8.29008C3.25 6.75108 3.51546 5.96528 3.95033 5.53041C4.3852 5.09553 5.171 4.83008 6.71 4.83008H13.03C14.569 4.83008 15.3548 5.09553 15.7897 5.53041C16.2245 5.96528 16.49 6.75108 16.49 8.29008V16.7101C16.49 18.2476 16.2226 19.0339 15.7864 19.4692C15.4522 19.8028 14.9128 20.0364 13.9902 20.1276C13.7662 20.6803 13.4737 21.1979 13.1231 21.6698C14.6974 21.6598 15.982 21.3933 16.8461 20.5309C17.7274 19.6512 17.99 18.3325 17.99 16.7101V8.29008C17.99 6.66908 17.7305 5.34987 16.8503 4.46975C15.9702 3.58962 14.651 3.33008 13.03 3.33008H6.71C5.089 3.33008 3.7698 3.58962 2.88967 4.46975C2.00954 5.34987 1.75 6.66908 1.75 8.29008V13.5068C2.16586 12.9091 2.67252 12.3794 3.25 11.9375V8.29008Z"
                fill="white"
              />
              <path
                d="M20.02 17.6001L17.24 15.6501V9.34013L20.02 7.39013C21.38 6.44013 22.5 7.02013 22.5 8.69013V16.3101C22.5 17.9801 21.38 18.5601 20.02 17.6001Z"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13 9.5C13.8284 9.5 14.5 8.82843 14.5 8C14.5 7.17157 13.8284 6.5 13 6.5C12.1716 6.5 11.5 7.17157 11.5 8C11.5 8.82843 12.1716 9.5 13 9.5Z"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <circle
                cx="7.5"
                cy="17.5"
                r="5.25"
                stroke="white"
                stroke-width="1.5"
              />
              <path d="M7.5 14.5V20.5" stroke="white" stroke-width="1.5" />
              <path d="M10.5 17.5L4.5 17.5" stroke="white" stroke-width="1.5" />
            </svg>
            {{ $t("multidisciplinary_board.btn_add_new_mdt") }}
          </button>
        </div>
      </div>
    </div>
    <!-- end body -->
    <div class="loading" v-if="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="mdt-room-detail" v-show="showing_mdt_room">
      <div class="row m-0">
        <div
          class="d-none d-lg-block col-lg-2"
          @click="hideMdtRoomDetail()"
        ></div>
        <div class="col-md-12 col-lg-10 content-room">
          <div class="_header">
            <span></span>
            <div class="d-flex align-items-center">
              <svg
                v-if="isMaster || isOwner"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="cursor-pointer mx-1"
                @click="goToUpdateMdt"
              >
                <path
                  d="M14.059 9.02L14.979 9.94L5.91902 19H4.99902V18.08L14.059 9.02ZM17.659 3C17.409 3 17.149 3.1 16.959 3.29L15.129 5.12L18.879 8.87L20.709 7.04C21.099 6.65 21.099 6.02 20.709 5.63L18.369 3.29C18.169 3.09 17.919 3 17.659 3ZM14.059 6.19L2.99902 17.25V21H6.74902L17.809 9.94L14.059 6.19Z"
                  fill="black"
                />
              </svg>
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="cursor-pointer mx-1"
                @click="hideMdtRoomDetail()"
              >
                <path
                  d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
          <MdtRoomDetail
            v-if="showDetail === 'WS'"
            :showing_room="showing_mdt_room"
            :loading_room="loading_room"
          />
          <OrganizationMdtRoomDetail
            v-else
            :showing_room="showing_mdt_room"
            :loading_room="loading_room"
          />
        </div>
      </div>
    </div>
    <!-- /.modal -->
  </div>
</template>

<script>
import { IconSearch } from '@/components/Icon'
import { debounce } from 'lodash'
import MdtRoomDetail from '../../components/HoiChan/MdtRoomDetail.vue'
import MeetRoomDetail from '../../components/Meets/MeetRoomDetail.vue'
import appUtils from '../../utils/appUtils'
import { APP_MODE } from '../../utils/constants'
import mdtUtils from '../../utils/mdtUtils'
import OrganizationMdtRoomDetail from '../Organization/OrganizationMdtRoomDetail.vue'

export default {
  name: 'ListMdt',
  components: {
    IconSearch,
    MdtRoomDetail,
    MeetRoomDetail,
    OrganizationMdtRoomDetail
  },
  data () {
    const MDT_ROOM_OPTIONS = {
      oneTime: 0,
      day: 1,
      week: 2,
      all: 3
    }

    const DEFAULT_SIZE_OF_PAGE = 6

    return {
      mdt_rooms: [],
      page: 1,
      loading: false,
      loading_room: false,
      showing_mdt_room_id: null,
      showing_mdt_room: null,
      mdtUtils,
      doctor_id: null,
      showing_disease: null,
      dtr_member: null,
      meet_concludings: [],
      isActive: 'HoiChan',
      listOrg: [],
      isAction: null,
      showDetail: null,
      getAllOrg: [],
      mdtFilter: MDT_ROOM_OPTIONS.day,
      mdtRoomOptions: MDT_ROOM_OPTIONS,
      totalItems: null,
      valueSearch: '',
      DEFAULT_SIZE_OF_PAGE
    }
  },
  mounted () {
    this.getRooms()
    if (!this.$globalClinic && !this.$globalOrg) this.getOrgList()
    this.doctor_id = appUtils.getLocalUser()?.doctor?.id

    if (this.$route.query.mdtid) {
      this.onShowMdtRoom(
        this.$route.query.mdtid,
        this.$globalClinic,
        this.$globalOrg || this.$route.query.type
      )
      setTimeout(() => {
        let query = Object.assign({}, this.$route.query)
        delete query.mdtid
        this.$router.replace({ query })
      }, 100)
    }
  },
  watch: {
    '$route.query.view': {
      handler () {
        this.getRooms()
      }
    },
    propertyPageAndFilter (value) {
      const [page, mdtFilter] = value.split(' ')
      if (page || mdtFilter) {
        this.handleChangeFilter()
      }
    }
  },
  computed: {
    isMaster () {
      return this.dtr_member?.role === 4 || this.dtr_member?.role === 1
    },
    isOwner () {
      return this.showing_mdt_room?.doctor_id === this.doctor_id
    },
    countItems () {
      return this.page * 6 > this.totalItems ? this.totalItems : this.page * 6
    },
    propertyPageAndFilter () {
      return `${this.page} ${this.mdtFilter}`
    }
  },
  methods: {
    async getRooms (data) {
      this.getAction()
      try {
        this.loading = true
        if (this.isAction === 'WS') await this.getMdtRooms(data)
        else if (this.isAction === 'ORG') await this.getOrgMdt('', data)
        else {
          await this.getMdtRooms(data)
          await this.getOrgMdt('NEWBIE', data)
        }
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    async getMdtRooms (data) {
      let self = this
      let params = {
        page: self.page,
        limit: self.DEFAULT_SIZE_OF_PAGE,
        ...data
        // clinic_id: 1
      }
      if (this.valueSearch) {
        params.search = this.valueSearch
      }
      if (Number(this.mdtFilter) !== this.mdtRoomOptions.all) {
        params.repeat_type = Number(this.mdtFilter)
      }

      await self.$rf
        .getRequest('DoctorRequest')
        .getMdtRooms(params)
        .then((r) => {
          self.mdt_rooms = r.data?.data
          self.totalItems = r.data?.total || 0
        })
        .finally(() => {
          return true
        })
    },
    onShowMdtRoom (id, clinic, org) {
      if (clinic) this.showDetail = 'WS'
      if (org) this.showDetail = 'ORG'
      this.showMdtRoom(id, clinic, org)
    },
    async showMdtRoom (id, clinic, org) {
      let self = this
      self.showing_mdt_room_id = id
      self.loading_room = true

      try {
        let res
        if (org) {
          res = await this.$rf
            .getRequest('DoctorRequest')
            .getPublicMtdRoomDetails(id)
        }
        if (clinic) {
          res = await this.$rf.getRequest('DoctorRequest').getMDTRoomDetail(id)

          this.$store.commit('mdtStore/setMdtRoomDetail', res.data)
        }
        this.showing_mdt_room = res.data
        if (clinic) {
          this.dtr_member =
            res?.data?.members?.find((m) => m.user_id === this.$user?.id) || {}
        }
        if (org) {
          this.dtr_member =
            res?.data?.members?.find((m) => m.user_id === this.$user?.id) || {}
        }
      } catch (e) {
        this.$toast.open({
          message: this.$t('multidisciplinary_board.lbl_room_info_not_found'),
          type: 'warning'
        })
      } finally {
        this.loading_room = false
      }
    },
    goToCreate () {
      if (this.isAction === 'WS' && this.$appMode === APP_MODE.workspace) {
        this.$router.push({ name: 'CreateMdt' })
        return
      }

      if (this.isAction === 'ORG' && this.$appMode === APP_MODE.organization) {
        this.$router.push({ name: 'OrganizationCreateMtd' })
      } else {
        this.$message({
          type: 'warning',
          message: `Bạn cần truy cập vào ORGANIZATION MODE để tạo mới phòng Hội trường`
        })
      }
    },
    hideMdtRoomDetail () {
      this.showing_mdt_room = null
      this.showing_mdt_room_id = null
    },
    goToUpdateMdt () {
      if (!this.showing_mdt_room) return
      this.$globalOrg
        ? this.$router.push({
          name: 'OrganizationMdtRoomUpdate',
          params: { id: this.showing_mdt_room.id }
        })
        : this.$router.push({
          name: 'MdtRoomUpdate',
          params: { id: this.showing_mdt_room.id }
        })
    },
    getNameOrg (id) {
      const orgName = this.listOrg.find((item) => {
        return item.id === id
      })
      if (!orgName) {
        const orgInfo =
          this.getAllOrg.find((item) => {
            return item.id === id
          }) || {}
        return orgInfo.name
      } else return orgName.name
    },
    async getOrgList () {
      const res = await this.$rf.getRequest('DoctorRequest').getOrgList()

      this.getAllOrg = res.data.data
    },
    async getOrgMdt (newbie = '', data) {
      if (!newbie) {
        this.mdt_rooms = []
        const res = await this.$rf.getRequest('DoctorRequest').getOrgs()
        this.listOrg = res.data
        this.listOrg.map(async (item) => {
          const params = {
            organization_id: item.id,
            page: this.page,
            limit: this.DEFAULT_SIZE_OF_PAGE,
            ...data
          }

          if (this.valueSearch) {
            params.search = this.valueSearch
          }
          if (Number(this.mdtFilter) !== this.mdtRoomOptions.all) {
            params.repeat_type = Number(this.mdtFilter)
          }

          const res2 = await this.$rf
            .getRequest('DoctorRequest')
            .getPublicMtdRooms(params)
          // this.mdt_rooms = [...this.mdt_rooms, ...res2.data.data]
          this.mdt_rooms = [...this.mdt_rooms, ...res2.data.data].filter(
            (item1, index, self) =>
              index === self.findIndex((item2) => item2?.id === item1?.id)
          )
        })
      } else {
        let params = {
          page: this.page,
          limit: this.DEFAULT_SIZE_OF_PAGE,
          ...data
          // clinic_id: 1
        }
        if (this.valueSearch) {
          params.search = this.valueSearch
        }
        if (Number(this.mdtFilter) !== this.mdtRoomOptions.all) {
          params.repeat_type = Number(this.mdtFilter)
        }

        const res2 = await this.$rf
          .getRequest('DoctorRequest')
          .getPublicMtdRooms(params)
        this.listOrg = res2.data?.data
        this.mdt_rooms = [...this.mdt_rooms, ...res2.data.data].filter(
          (item1, index, self) =>
            index === self.findIndex((item2) => item2?.id === item1?.id)
        )
      }
    },
    getAction () {
      if (this.isActive === 'HoiChan') {
        if (this.$route.query?.view === 'hall') {
          this.isAction = 'ORG'
          return
        } else {
          this.$router
            .replace({
              query: {}
            })
            .catch(() => {})
        }

        if (this.$globalClinic) this.isAction = 'WS'
        else if (this.$globalOrg) this.isAction = 'ORG'
        else {
          this.isAction = 'NEWBIE'
        }
      }
    },
    async handleChangeFilter (data) {
      try {
        this.loading = true
        if (this.isAction === 'WS') await this.getMdtRooms(data)
        else if (this.isAction === 'ORG') await this.getOrgMdt('', data)
        else {
          await this.getMdtRooms(data)
          await this.getOrgMdt('NEWBIE', data)
        }
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    handleChangePage: debounce(function (newPage) {
      this.page = newPage
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .room-item {
    margin-bottom: 16px;
    border-radius: 10px;
    width: 100%;
    background: #fff;
    box-shadow: 0 8px 8px 0 rgba(196, 196, 196, 0.11),
      0 12px 18px 0 rgba(184, 184, 184, 0.11);
  }

  .active-item {
    background-color: #20419b;
    color: white;
  }

  .mdt-room-detail {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1041;
  }

  .mdt-room-detail ._header {
    display: flex;
    justify-content: space-between;
  }

  .mdt-room-detail .content-room {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
    padding-left: 32px;
    padding-right: 0;
    padding-top: 16px;
    background: #fff;
    height: 100vh;
  }

  .body-list {
    min-height: 500px;
  }

  .img-1 {
    width: 100%;
    max-width: 420px;
    height: auto;
    margin: 0 auto;
  }

  .border-bottom-2 {
    border-bottom: 2px solid #20419b !important;
  }

  .min-w-48 {
    min-width: 12rem;
  }

  .loading {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }

  .spinner-border {
    width: 3rem;
    height: 3rem;
  }

  .input-search-box span,
  .input-search-box input {
    background-color: inherit !important;
  }

  .input-search-box {
    border: 1.0625px solid #c3cae7;
    max-width: 100%;
  }

  .bg-bold-item {
    background-color: #e7eaf5 !important;
  }

  .form-control {
    height: 40px;
  }

  .form-select {
    background-image: url(../../../public/assets/images/icon/sort-icon.svg);
    background-size: 24px;
    background-position: right 0.25rem center;
  }

  .form-select-arrow {
    background-image: url(../../../public/assets/images/icon/sort_down.png);
    background-size: 16px;
    background-position: left center;
    background-repeat: no-repeat;
  }

  .el-pagination-mdt {
    padding: 0;

    & .number.active {
      background-color: #20419b !important;
    }
  }
}
</style>
