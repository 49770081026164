<template>
  <div v-loading="isLoading" class="p-3 pb-5 mb-5">
    <div class="d-flex align-items-center">
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="mr-2"
      >
        <path
          d="M30.5401 19.0954C29.9851 19.0954 29.5201 18.6754 29.4601 18.1204C29.1001 14.8204 27.3301 11.8504 24.6001 9.96035C24.1051 9.61535 23.9851 8.94035 24.3301 8.44535C24.6751 7.95035 25.3501 7.83035 25.8451 8.17535C29.1001 10.4404 31.2001 13.9804 31.6351 17.8954C31.6951 18.4954 31.2601 19.0354 30.6601 19.0954C30.6151 19.0954 30.5851 19.0954 30.5401 19.0954Z"
          fill="#20419B"
        />
        <path
          d="M5.6097 19.1698C5.5797 19.1698 5.5347 19.1698 5.5047 19.1698C4.9047 19.1098 4.4697 18.5698 4.5297 17.9698C4.9347 14.0548 7.0047 10.5148 10.2297 8.23481C10.7097 7.88981 11.3997 8.00981 11.7447 8.48981C12.0897 8.98481 11.9697 9.65981 11.4897 10.0048C8.7897 11.9248 7.0347 14.8948 6.7047 18.1798C6.6447 18.7498 6.1647 19.1698 5.6097 19.1698Z"
          fill="#20419B"
        />
        <path
          d="M23.9851 31.65C22.1401 32.535 20.1602 32.985 18.0902 32.985C15.9302 32.985 13.8752 32.505 11.9552 31.53C11.4152 31.275 11.2052 30.615 11.4752 30.075C11.7302 29.535 12.3902 29.325 12.9302 29.58C13.8752 30.06 14.8802 30.39 15.9002 30.585C17.2802 30.855 18.6902 30.87 20.0702 30.63C21.0902 30.45 22.0952 30.135 23.0252 29.685C23.5802 29.43 24.2401 29.64 24.4801 30.195C24.7501 30.735 24.5401 31.395 23.9851 31.65Z"
          fill="#20419B"
        />
        <path
          d="M18.075 3.01514C15.75 3.01514 13.845 4.90514 13.845 7.24514C13.845 9.58514 15.735 11.4751 18.075 11.4751C20.415 11.4751 22.305 9.58514 22.305 7.24514C22.305 4.90514 20.415 3.01514 18.075 3.01514Z"
          fill="#20419B"
        />
        <path
          d="M7.57497 20.8052C5.24997 20.8052 3.34497 22.6952 3.34497 25.0352C3.34497 27.3752 5.23497 29.2652 7.57497 29.2652C9.91497 29.2652 11.805 27.3752 11.805 25.0352C11.805 22.6952 9.89997 20.8052 7.57497 20.8052Z"
          fill="#20419B"
        />
        <path
          d="M28.4248 20.8052C26.0998 20.8052 24.1948 22.6952 24.1948 25.0352C24.1948 27.3752 26.0848 29.2652 28.4248 29.2652C30.7648 29.2652 32.6548 27.3752 32.6548 25.0352C32.6548 22.6952 30.7648 20.8052 28.4248 20.8052Z"
          fill="#20419B"
        />
      </svg>
      <p class="robo-24-500 txt-pri mb-0">{{$t('multidisciplinary_board.lbl_type_mdt')}}</p>
    </div>
    <div class="my-4">
      <p class="robo-24-500 txt-black">{{$t('multidisciplinary_board.btn_add_new_mdt')}}</p>
      <div class="d-flex align-items-center txt-grey-600 cursor-pointer" @click="goToList">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="mr-2"
        >
          <path
            d="M16.2541 4.24106C16.5522 4.53326 16.5793 4.99051 16.3354 5.31272L16.2541 5.40503L9.52658 12L16.2541 18.595C16.5522 18.8872 16.5793 19.3444 16.3354 19.6666L16.2541 19.7589C15.956 20.0511 15.4896 20.0777 15.161 19.8386L15.0668 19.7589L7.7459 12.582C7.44784 12.2898 7.42074 11.8325 7.66461 11.5103L7.7459 11.418L15.0668 4.24106C15.3947 3.91965 15.9262 3.91965 16.2541 4.24106Z"
            fill="#616673"
          />
        </svg>
        <span>{{$t('multidisciplinary_board.lbl_back')}}</span>
      </div>
    </div>
    <div class="mb-5">
      <div class="row mb-3">
        <div class="col-lg-4 col-md-5 col-xl-3">
          <label
            class="robo-18-400 required mt-1 txt-black"
          >{{$t('multidisciplinary_board.lbl_room_name')}}</label>
        </div>
        <div class="col-lg-8 col-md-7 col-xl-9">
          <input type="text" class="form-control border radius-10" v-model="form.name" />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-4 col-md-5 col-xl-3">
          <label
            class="robo-18-400 required mt-1 txt-black"
          >{{$t('multidisciplinary_board.lbl_room_time')}}</label>
        </div>
        <div class="col-lg-8 col-md-7 col-xl-9">
          <div class="row">
            <div class="col-md-6 d-flex">
              <input
                type="date"
                class="form-control w-50 border radius-10 mr-1"
                v-model="form.date"
                :min="today"
              />
              <input
                type="time"
                class="form-control w-50 border radius-10"
                v-model="form.start_time"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-4 col-md-5 col-xl-3">
          <label
            class="robo-18-400 mt-1 txt-black"
          >{{$t('multidisciplinary_board.lbl_room_sequence')}}</label>
        </div>
        <div class="col-md-6">
          <select v-model="form.repeat_type" class="form-select border radius-10">
            <option :value="0">{{$t('multidisciplinary_board.lbl_norepeat')}}</option>
            <option :value="1">{{$t('multidisciplinary_board.lbl_daily')}}</option>
            <option :value="2">{{$t('multidisciplinary_board.lbl_weekly')}}</option>
          </select>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-4 col-md-5 col-xl-3">
          <label class="robo-18-400 mt-1 txt-black">{{$t('multidisciplinary_board.lbl_location')}}</label>
        </div>
        <div class="col-lg-8 col-md-7 col-xl-9">
          <input type="text" class="form-control border radius-10" v-model="form.location" />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-4 col-md-5 col-xl-3">
          <label class="robo-18-400 mt-1 txt-black">{{$t('multidisciplinary_board.lbl_pwd')}}</label>
        </div>
        <div class="col-lg-8 col-md-7 col-xl-9">
          <input type="text" class="form-control border radius-10" v-model="form.password" />
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-center py-3">
        <button
          :disabled="isLoading"
          type="button"
          class="btn btn-white bd-pri txt-pri px-5 mr-2"
          @click="goToList"
        >{{$t('multidisciplinary_board.btn_cancel')}}</button>
        <button
          :disabled="isLoading"
          type="button"
          class="btn bg-pri bd-pri text-white px-5"
          @click="onSubmit"
        >{{$t('multidisciplinary_board.btn_save')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreateMdt',
  data () {
    return {
      form: {
        name: '',
        date: '',
        start_time: null,
        end_time: '23:59:59',
        repeat_type: 0,
        location: '',
        password: ''
      },
      today: window.moment().format('YYYY-MM-DD'),
      isLoading: false
    }
  },
  mounted () {
    this.form.date = this.today
  },
  methods: {
    goToList () {
      this.$router.push({ name: 'ListMdt' })
    },
    async onSubmit () {
      let self = this
      self.isLoading = true

      try {
        let form = {
          name: self.form.name,
          date: self.form.date,
          start_time: self.form.start_time,
          end_time: self.form.end_time,
          repeat_type: self.form.repeat_type,
          location: self.form.location,
          password: self.form.password,
          clinic_id: self.$clinicId
        }
        if (!form.name) {
          alert('Vui lòng nhập tên phòng hội chẩn')
          return false
        }
        if (!form.date) {
          alert('Vui lòng chọn ngày diễn ra hội chẩn')
          return false
        } else {
          form.date = window.moment(form.date).format('DD-MM-YYYY')
        }
        if (!form.start_time) {
          alert('Vui lòng thời gian bắt đầu hội chẩn')
          return false
        } else {
          form.start_time = window
            .moment(form.start_time, 'HH:mm')
            .format('HH:mm:ss')
        }
        if (!form.start_time) {
          delete form.start_time
          delete form.end_time
        } else {
          form.start_time = window
            .moment(form.start_time, 'HH:mm')
            .format('HH:mm:ss')
          form.end_time = window
            .moment(form.end_time, 'HH:mm')
            .format('HH:mm:ss')
        }
        await self.$rf
          .getRequest('DoctorRequest')
          .createMdtRoom(form)
          .then(r => {
            self.$router.push({ name: 'ListMdt', query: { mdtid: r.data.id } })
          })
      } catch (error) {
        console.log(error)
      } finally {
        self.isLoading = false
      }
    }
  }
}
</script>

<style scoped>
.form-control {
  background: #fff;
  height: 42px;
  font-size: 16px;
  color: black;
}
</style>