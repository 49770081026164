<template>
  <div v-loading="loading" class="p-3 radius-10 bg-white">
    <MdtDiseaseForm @submit="addMdtDiseases" @onClose="onClose" :loading="loading" :room="room" />
  </div>
</template>

<script>
import MdtDiseaseForm from '../../components/HoiChan/MdtDiseaseForm.vue'
import { mapState } from 'vuex'

export default {
  name: 'CreateMdtDisease',
  components: { MdtDiseaseForm },
  data () {
    return {
      room: null,
      loading: false
    }
  },
  computed: {
    ...mapState({
      mdtRoomStore: state => state.mdtStore.mdtRoomDetail || {}
    })
  },
  mounted () {
    this.showMdtRoom()
  },
  methods: {
    onClose () {
      this.$router.push({
        name: 'ListMdt',
        query: { mdtid: this.$route.params.room_id }
      })
    },
    async showMdtRoom () {
      let self = this
      if (self.mdtRoomStore?.id === Number(this.$route.params.room_id)) {
        const clonedMdtRoom = { ...self.mdtRoomStore }

        self.room = clonedMdtRoom

        return clonedMdtRoom
      } else {
        self.loading = true
        self.$rf
          .getRequest('DoctorRequest')
          .getMDTRoomDetail(this.$route.params.room_id)
          .then(r => {
            self.room = r.data
            this.$store.commit('mdtStore/setMdtRoomDetail', r.data)
          })
          .catch(e => {
            console.log(e)
            self.$router.push({ name: 'ListMdt' })
          })
          .finally(() => {
            self.loading = false
          })
      }
    },
    async addMdtDiseases (form, alias_params) {
      this.loading = true
      try {
        form.set('mdt_room_id', this.$route.params.room_id)
        form.set('status', 0)
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .addMdtDiseasesV3(form)

        if (res.data) {
          if (alias_params) {
            await this.setAliasPerson({ mtdDiseases: res.data, alias_params })
          }
          this.goToDetail(res.data.id)
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async setAliasPerson ({ mtdDiseases, alias_params }) {
      if (!mtdDiseases?.id) {
        this.$toast.open({
          message: `mdt_room_disease_id no empty`,
          type: 'error'
        })
        return
      }

      try {
        const { alias_id, ...alias_form } = alias_params

        const params = {
          ...alias_form,
          mdt_room_disease_id: mtdDiseases.id,
          medical_record_id: null,
          person_id: Number(mtdDiseases?.person_id) || null
        }

        await this.$rf
          .getRequest('DoctorRequest')
          .setAliasPerson(params)
          .then(res => {
            // console.log(res)
          })
      } catch (error) {
        console.log(error)
      }
    },
    goToDetail (id) {
      this.$router.push({ name: 'MdtDiseaseDetail', params: { id: id } })
    }
  }
}
</script>

<style scoped>
.form-control {
  background: #ffffff;
  /* Neutral/300 */

  border: 1.5px solid #b4b4c4;
  border-radius: 10px;
  color: #616673;
}

label {
  margin-bottom: 10px;
}

.btn-save {
  height: 50px;
  width: 190px;
  border-radius: 8px;
  padding: 8px, 16px, 8px, 16px;
}
</style>
